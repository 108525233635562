import { queryOptions, useQuery } from "@tanstack/react-query";
import appService from "../../../services/app";

export const appQueryOptions = ({ id }) => queryOptions({
  queryKey: ["APP", {
    id,
  }],
  queryFn: async () => {
    const { data } = await appService.getApp(id);

    return data;
  },
});

const useAppQuery = ({
                       enabled,
                       id,
                     }) => {
  const { data, isFetching, isPending, error, isError, refetch } = useQuery({
    ...appQueryOptions({
      id,
    }),
    enabled,
    retry: 0,
    refetchOnWindowFocus: false,
  });

  return {
    data,
    isNotFound: error?.response?.status === 404,
    error,
    isError,
    refetch,
    isFetching,
    isPending,
  };
};

export default useAppQuery;
