import merge from "lodash/fp/merge";
import getId from "../../../helpers/fp/models/getId";
import { virtualMachineQueryOptions } from "../queries/useVirtualMachineQuery";
import { virtualMachinesQueryOptions } from "../queries/useVirtualMachinesQuery";

const updateCachedVirtualMachine = (queryClient, virtualMachine) => {
  const virtualMachineQueryKey = virtualMachineQueryOptions({
    id: getId(virtualMachine)
  }).queryKey;

  const virtualMachinesQueryKey = virtualMachinesQueryOptions({
    teamId: getId(virtualMachine.team),
    projectId: getId(virtualMachine.project),
  }).queryKey;

  const cachedVirtualMachine = queryClient.getQueryData(virtualMachineQueryKey);
  const cachedVirtualMachines = queryClient.getQueryData(virtualMachinesQueryKey);

  if (cachedVirtualMachine) {
    const updatedDatabase = merge(cachedVirtualMachine, virtualMachine);
    queryClient.setQueryData(virtualMachineQueryKey, updatedDatabase);
  }

  if (cachedVirtualMachines && !!cachedVirtualMachines.length) {
    const updatedVirtualMachines = cachedVirtualMachines.map(_virtualMachine => getId(_virtualMachine) === getId(virtualMachine)
      ? merge(_virtualMachine, virtualMachine) : _virtualMachine);
    queryClient.setQueryData(virtualMachinesQueryKey, updatedVirtualMachines);
  }
}

export default updateCachedVirtualMachine;
