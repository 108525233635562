import getId from "../../../helpers/fp/models/getId";
import { appsQueryOptions } from "../queries/useAppsQuery";

const deleteCachedApp = (queryClient, app) => {
  const appsQueryKey = appsQueryOptions({
    teamId: getId(app.team),
    projectId: getId(app.project)
  }).queryKey;

  const cachedApps = queryClient.getQueryData(appsQueryKey);

  if (cachedApps && !!cachedApps.length) {
    const updatedApps = cachedApps.filter(_app => getId(_app) !== getId(app));
    queryClient.setQueryData(appsQueryKey, updatedApps);
  }
}

export default deleteCachedApp;
