import merge from "lodash/fp/merge";
import { clustersQueryOptions } from "../../queries/useClustersQuery";
import getId from "../../../../helpers/fp/models/getId";

const updateCachedMasterNode = (queryClient, masterNode) => {
  const clustersQueryKey = clustersQueryOptions({
    teamId: getId(masterNode.cluster.team),
    projectId: getId(masterNode.cluster.project)
  }).queryKey;

  const cachedClusters = queryClient.getQueryData(clustersQueryKey);

  if (!cachedClusters && !cachedClusters.length) {
    return;
  }

  const updatedClusters = cachedClusters
    .map(cachedCluster =>
      getId(cachedCluster) === getId(masterNode.cluster) &&
      getId(cachedCluster.masterNode) === getId(masterNode) ?
        {
          ...cachedCluster,
          masterNode: merge(cachedCluster.masterNode, masterNode),
        } : cachedCluster
    );

  queryClient.setQueryData(clustersQueryKey, updatedClusters);
}

export default updateCachedMasterNode;
