import getId from "../../../../helpers/fp/models/getId";
import { clustersQueryOptions } from "../../queries/useClustersQuery";

const deleteCachedCluster = (queryClient, cluster) => {
  const clustersQueryKey = clustersQueryOptions({
    teamId: getId(cluster.team),
    projectId: getId(cluster.project)
  }).queryKey;

  const cachedClusters = queryClient.getQueryData(clustersQueryKey);

  if (!cachedClusters || !cachedClusters.length) {
    return;
  }

  const updatedClusters = cachedClusters.filter(_cluster => getId(_cluster) !== getId(cluster));
  queryClient.setQueryData(clustersQueryKey, updatedClusters);
}

export default deleteCachedCluster;
