import { useDispatch } from "react-redux";

import teamStore from "../../store/team";
import getId from "../../helpers/fp/models/getId";

import useAuthUser from "../user/useAuthUser";

const useFetchCurrentTeam = () => {
  const dispatch = useDispatch();
  const userId = getId(useAuthUser());

  return () => userId
    ? dispatch(teamStore.asyncActions.getCurrentTeam())
    : Promise.resolve(null);
}

export default useFetchCurrentTeam;
