// hooks
import useFetchRetrieveByBillingSettingsId from "../useFetchRetrieveByBillingSettingsId";

// helpers
import resolveTrue from "../../../helpers/fp/promise/resolveTrue";
import useFetchPaymentMethodsByBillingSettingsId from "../../paymentMethods/useFetchPaymentMethodsByBillingSettingsId";


const useCheckPaymentVerifiedByBillingSettingsId = () => {
  const fetchRetrieve                          = useFetchRetrieveByBillingSettingsId();
  const fetchPaymentMethodsByBillingSettingsId = useFetchPaymentMethodsByBillingSettingsId();

  return (billingSettingsId) => billingSettingsId ? fetchRetrieve(billingSettingsId)
      .then((reducer) => {
        const { meta, payload } = reducer;
        if (meta.requestStatus === "fulfilled") {
          const defaultPaymentMethod = payload.data?.invoice_settings?.default_payment_method;
          if (!defaultPaymentMethod) return true;
          return fetchPaymentMethodsByBillingSettingsId(billingSettingsId).then((reducer) => {
            const paymentMethods = reducer.payload.data;
            if (!paymentMethods) return true;
            const paymentMethod = paymentMethods.find((paymentMethod) => paymentMethod.id === defaultPaymentMethod);
            if (!paymentMethod) return true;
            const { card } = paymentMethod;
            if (!card) return true;

            const expMonth = paymentMethod.card.exp_month;
            const expYear  = paymentMethod.card.exp_year;
            if (!expMonth || !expYear) return true;
            const expDate  = new Date(expYear, expMonth, 1, 0, 0, 0, 0);
            const expTime  = expDate.getTime();
            if (Number.isNaN(expTime)) return true;
            return expTime < Date.now();
          });
        }
        return Promise.reject(new Error(reducer));
      })
    : resolveTrue
  ;
};

export default useCheckPaymentVerifiedByBillingSettingsId;
