import { teamRoles } from "../../../../../constants/team";
import empty from "../../../array/empty";
import memoizeWeak from "../../../decorators/memoize/weak";

const _teamOwners = memoizeWeak((team) => team.members
  .filter((member) => member.role === teamRoles.owner)
  .map((member) => member.user)
  .filter(Boolean)
);

const teamOwners = (team) => team
  ? _teamOwners(team)
  : empty()
;

export default teamOwners;
