import { useDispatch } from "react-redux";
import actualCostAsyncActions from "../../store/actualCost/asyncActions";
import trap from "../../helpers/fp/decorators/trap";

const useFetchActualCostsByTeam = () => {
  const dispatch = useDispatch();
  return trap((team) => dispatch(actualCostAsyncActions.getByTeam(team)));
};

export default useFetchActualCostsByTeam;
