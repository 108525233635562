import merge from "lodash/fp/merge";
import getId from "../../../helpers/fp/models/getId";
import { databaseQueryOptions } from "../queries/useDatabaseQuery";
import { databasesQueryOptions } from "../queries/useDatabasesQuery";

const updateCachedDatabase = (queryClient, db) => {
  const databaseQueryKey = databaseQueryOptions({
    id: getId(db)
  }).queryKey;

  const databasesQueryKey = databasesQueryOptions({
    teamId: getId(db.team),
    projectId: getId(db.project),
  }).queryKey;

  const cachedDatabase = queryClient.getQueryData(databaseQueryKey);
  const cachedDatabases = queryClient.getQueryData(databasesQueryKey);

  if (cachedDatabase) {
    const updatedDatabase = merge(cachedDatabase, db);
    queryClient.setQueryData(databaseQueryKey, updatedDatabase);
  }

  if (cachedDatabases && !!cachedDatabases.length) {
    const updatedDatabases = cachedDatabases.map(_db => getId(_db) === getId(db) ? merge(_db, db) : _db);
    queryClient.setQueryData(databasesQueryKey, updatedDatabases);
  }
}

export default updateCachedDatabase;
