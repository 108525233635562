import merge from "lodash/fp/merge";
import getId from "../../../../helpers/fp/models/getId";
import { clustersQueryOptions } from "../../queries/useClustersQuery";

const updateCachedCluster = (queryClient, cluster) => {
  const clustersQueryKey = clustersQueryOptions({
    teamId: getId(cluster.team),
    projectId: getId(cluster.project)
  }).queryKey;

  const cachedClusters = queryClient.getQueryData(clustersQueryKey);

  if (!cachedClusters || !cachedClusters.length) {
    return;
  }

  const updatedClusters = cachedClusters
    .map(_cluster => getId(_cluster) === getId(cluster)
    ? merge(_cluster, cluster) : _cluster
  );

  queryClient.setQueryData(clustersQueryKey, updatedClusters);
}

export default updateCachedCluster;
