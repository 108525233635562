// CORE
import React from "react";
import { Navigate, useLocation } from "react-router-dom";
// HOOKS
import useServerMode from "../hooks/serverMode/useServerMode";
// CONSTANTS
import { maintenance,waitingList,registrationsRoute } from "../constants/routes/routes";
// COMPONENTS
import PageLoader from "../components/shared/PageLoader";

const ServerModeProvider = ({ children }) => {
  const { isShowLoader, isShowMaintenance ,isShowWaitingList } = useServerMode();
  const location = useLocation();

  if (isShowLoader) return (<PageLoader />);
  if (isShowMaintenance) {
    if (location.pathname !== maintenance) {
      return <Navigate to={maintenance} />;
    }
  } else {
    if (location.pathname === maintenance) {
      const projectId = localStorage.getItem("project");
      const baseURL = (projectId ? `/projects/${projectId}` : "/");
      return <Navigate to={baseURL} />;
    }
    if (isShowWaitingList) {
      if(location.pathname === registrationsRoute ){
        return <Navigate to={waitingList} />;
      }
    } else if(location.pathname === waitingList){
      return <Navigate to={registrationsRoute} />;
    }
  }

  return children;
};

export default ServerModeProvider;
