import getId from "../../../../helpers/fp/models/getId";
import { clustersQueryOptions } from "../../queries/useClustersQuery";

const addPoolToCachedCluster = (queryClient, pool) => {
  const clustersQueryKey = clustersQueryOptions({
    teamId: getId(pool.cluster.team),
    projectId: getId(pool.cluster.project)
  }).queryKey;

  const cachedClusters = queryClient.getQueryData(clustersQueryKey);

  if (!cachedClusters || !cachedClusters.length) {
    return;
  }

  const updatedClusters = cachedClusters
    .map(cachedCluster =>
      getId(cachedCluster) === getId(pool.cluster) ?
        {
          ...cachedCluster,
          pools: [
            ...cachedCluster.pools,
            pool
          ]
        }
        : cachedCluster
    );

  queryClient.setQueryData(clustersQueryKey, updatedClusters);
}

export default addPoolToCachedCluster;
