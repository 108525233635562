import getId from "../../../helpers/fp/models/getId";
import { virtualMachinesQueryOptions } from "../queries/useVirtualMachinesQuery";

const deleteCachedVirtualMachine = (queryClient, virtualMachine) => {
  const virtualMachinesQueryKey = virtualMachinesQueryOptions({
    teamId: getId(virtualMachine.team),
    projectId: getId(virtualMachine.project),
  }).queryKey;

  const cachedVirtualMachines = queryClient.getQueryData(virtualMachinesQueryKey);

  if (cachedVirtualMachines && !!cachedVirtualMachines.length) {
    const updatedVirtualMachines = cachedVirtualMachines.filter(_virtualMachine => getId(_virtualMachine) !== getId(virtualMachine));
    queryClient.setQueryData(virtualMachinesQueryKey, updatedVirtualMachines);
  }
}

export default deleteCachedVirtualMachine;
