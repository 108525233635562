import merge from "lodash/fp/merge";
import { clustersQueryOptions } from "../../queries/useClustersQuery";
import getId from "../../../../helpers/fp/models/getId";

const updateCachedWorkerNode = (queryClient, workerNode) => {
  const clustersQueryKey = clustersQueryOptions({
    teamId: getId(workerNode.pool.cluster.team),
    projectId: getId(workerNode.pool.cluster.project)
  }).queryKey;

  const cachedClusters = queryClient.getQueryData(clustersQueryKey);

  if (!cachedClusters && !cachedClusters.length) {
    return;
  }

  const updatedClusters = cachedClusters
    .map(cachedCluster =>
      getId(cachedCluster) === getId(workerNode.pool.cluster) ?
        {
          ...cachedCluster,
          pools: cachedCluster.pools
            .map(pool => getId(pool) === getId(workerNode.pool) ? {
              ...pool,
              nodes: pool.nodes
                .map(_workerNode => getId(_workerNode) === getId(workerNode)
                  ? merge(_workerNode, workerNode)
                  : _workerNode)
            } : pool)
        }
        : cachedCluster
    );

  queryClient.setQueryData(clustersQueryKey, updatedClusters);
}

export default updateCachedWorkerNode;
