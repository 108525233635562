import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ACCESSIBLE_UN_AUTH_ROUTES } from "../../../constants/routes/routes";
import accessToken from "../../../helpers/accessToken";

import empty       from "../../../helpers/fp/object/empty";
import userActions from "../../../store/user/actions";

let isThrottled   = "";
let timerId;
const THROTTLE_DELAY = 1000;
const throttleOn  = (_id) => (isThrottled = _id);
const throttleOff = () => (isThrottled = "");
const timeoutOff  = () => (clearTimeout(timerId), timerId = setTimeout(throttleOff, THROTTLE_DELAY));

const useAuthUser = (call) => {
  const authUser = useSelector(({ user }) => user.userData) || empty();
  const dispatch = useDispatch();
  const { value } = accessToken;
  const { updatedAt, _id } = authUser;
  const isNeedLoad = value && !ACCESSIBLE_UN_AUTH_ROUTES.includes(window.location.pathname);
  const fetchUserData = () => dispatch(userActions.fetchUserData());

  useEffect(() => {
    if (isNeedLoad && !isThrottled) {
      if (_id) {
        requestIdleCallback(() => {
          if (_id === isThrottled) return;
          throttleOn(_id);
          if (accessToken.value) fetchUserData().finally(timeoutOff);
          else throttleOff();
        });
      }

      else fetchUserData().then(({ payload }) => payload && payload._id && throttleOn(payload._id) && timeoutOff());
    }
  }, [updatedAt, _id, isNeedLoad]);

  return call && authUser ? call(authUser) : authUser;
};

export default useAuthUser;
