import { keepPreviousData, queryOptions, useQuery } from "@tanstack/react-query";
import k8sService from "../../../services/k8s";

export const clustersQueryOptions = ({ teamId, projectId }) => queryOptions({
  queryKey: ["CLUSTERS", {
    teamId, projectId,
  }],
  queryFn: async () => {
    const { data } = await k8sService.getClusters({ teamId, projectId });

    return data;
  },
});

const useClustersQuery = ({
                            enabled = true,
                            teamId,
                            projectId
                     }) => {
  const {
    data,
    isFetching,
    isPending,
    error,
    isError,
    refetch
  } = useQuery({
    ...clustersQueryOptions({
      teamId,
      projectId
    }),
    enabled,
    retry: 0,
    refetchOnWindowFocus: false,
    initialData: [],
    placeholderData: keepPreviousData
  })

  return {
    items: data,
    isEmpty: data && !data?.length,
    isNotFound: error?.response?.status === 404,
    error,
    isError,
    refetch,
    isFetching,
    isPending
  }
}

export default useClustersQuery;
