import getId from "../../../helpers/fp/models/getId";
import { databasesQueryOptions } from "../queries/useDatabasesQuery";

const deleteCachedDatabase = (queryClient, db) => {
  const databasesQueryKey = databasesQueryOptions({
    teamId: getId(db.team),
    projectId: getId(db.project),
  }).queryKey;

  const cachedDatabases = queryClient.getQueryData(databasesQueryKey);

  if (cachedDatabases && !!cachedDatabases.length) {
    const updatedDatabases = cachedDatabases.filter(_db => getId(_db) !== getId(db));
    queryClient.setQueryData(databasesQueryKey, updatedDatabases);
  }
}

export default deleteCachedDatabase;
