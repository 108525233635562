import { useSelector }     from "react-redux";
import { useLayoutEffect } from "react";

import getId               from "../../helpers/fp/models/getId";
import currentTeam         from "./currentTeam";

import useFetchCurrentTeam from "./useFetchCurrentTeam";
import useAuthUser from "../user/useAuthUser";

/**
  * @typedef {import("../../types/Team").default} Team
  */

/** @returns {Team | null} */
const useCurrentTeam = () => {
  const team             = useSelector(currentTeam);
  const fetchCurrentTeam = useFetchCurrentTeam();
  const userId           = getId(useAuthUser())

  useLayoutEffect(() => {
    if (!team && userId) fetchCurrentTeam();
  }, [team]);

  /** @type {Team | null} */
  return (team);
};

export default useCurrentTeam;
