import merge from "lodash/fp/merge";
import { appQueryOptions } from "../queries/useAppQuery";
import getId from "../../../helpers/fp/models/getId";
import { appsQueryOptions } from "../queries/useAppsQuery";

const updateCachedApp = (queryClient, app) => {
  const appQueryKey = appQueryOptions({
    id: getId(app)
  }).queryKey;

  const appsQueryKey = appsQueryOptions({
    teamId: getId(app.team),
    projectId: getId(app.project)
  }).queryKey;

  const cachedApp = queryClient.getQueryData(appQueryKey);
  const cachedApps = queryClient.getQueryData(appsQueryKey);

  if (cachedApp) {
    const updatedApp = merge(cachedApp, app);
    queryClient.setQueryData(appQueryKey, updatedApp);
  }

  if (cachedApps && !!cachedApps.length) {
    const updatedApps = cachedApps.map(_app => getId(_app) === getId(app) ? merge(_app, app) : _app);
    queryClient.setQueryData(appsQueryKey, updatedApps);
  }
}

export default updateCachedApp;
