// CORE
import { useEffect } from "react";
import { useSelector } from "react-redux";
// STORE
import empty from "../../helpers/fp/array/empty";
import getId from "../../helpers/fp/models/getId";
import memoizeWeak from "../../helpers/fp/decorators/memoize/weak";
import memoize from "../../helpers/fp/decorators/memoize";

import useFetchTeams       from "./useFetchTeams";
import useFetchCurrentTeam from "./useFetchCurrentTeam";
import useAuthUser from "../user/useAuthUser";

const teamsByUserId = memoize((userId) => memoizeWeak((teams) => {
  return teams.filter((team) => team?.members?.some((member) => getId(member.user) === userId));
}));

const useTeams = () => {
  const userId           = getId(useAuthUser());
  const teams            = useSelector((state) => state.team.items);
  const fetchTeams       = useFetchTeams();
  const fetchCurrentTeam = useFetchCurrentTeam();

  useEffect(() => {
    if (userId) {
      fetchCurrentTeam();
      requestIdleCallback(fetchTeams);
    }
  }, [userId]);

  return (teams?.length)
    ? teamsByUserId(userId)(teams)
    : empty()
  ;
};

export default useTeams;
