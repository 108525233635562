// CORE
import { createSlice } from '@reduxjs/toolkit';

import path      from "lodash/fp/path";
import reject    from "lodash/fp/reject";
import identical from 'lodash/fp/identical';

// ACTIONS
import asyncActions from './asyncActions';

// helpers
import empty    from "../../helpers/fp/array/empty";
import injectBy from '../../helpers/fp/array/inject/by';
import pipe from '../../helpers/fp/composition/pipe';

const pathCode     = path(['promo', 'code']);
const rejectByCode = (value) => reject(pipe([pathCode, identical(value)]));
const injectByCode = injectBy(pathCode);

const promoSlice = createSlice({
  name         : 'promo',
  initialState : empty(),
  reducers     : {
    clear           : empty,
    dropCode        : (state, { payload }) => rejectByCode(payload)(state),
    injectPromoCode : (state, { payload }) => injectByCode(payload)(state),
  },
  extraReducers: builder => {
    builder
      .addCase(asyncActions.list.fulfilled           , (state, { payload }) => payload)
      .addCase(asyncActions.applyPromoCode.fulfilled , (state, { payload }) => injectByCode(payload)(state))
      .addCase(asyncActions.delete.fulfilled         , (state, { meta })    => rejectByCode(meta.arg)(state))

      .addCase(asyncActions.activate.pending , (state, { meta })    => {
        const code = meta.arg;
        return state.map(userPromo => userPromo.promo.code === code ? ({ ...userPromo, active: true }) : userPromo);
      })

      .addCase(asyncActions.deactivate.pending , (state, { meta })    => {
        const code = meta.arg;
        return state.map(userPromo => userPromo.promo.code === code ? ({ ...userPromo, active: false }) : userPromo);
      })
    ;
  },
});

const promoStore = Object.freeze({
  ...promoSlice,
  asyncActions,
});

export default promoStore;
